.App {
  h1 {
    text-align: center;
  }

  main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 16px;

    p {
      padding: 8px 0;
    }

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 960px) {
      grid-template-columns: 1fr;
    }

    section {
      margin: 8px;
      border-radius: 4px;
    }
  }
}